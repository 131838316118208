import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import Constants from "expo-constants";
import * as WebBrowser from "expo-web-browser";
import Pattern from "./Pattern";

const Adjustable = ({
  size,
  text,
  color = "#000000",
  family = "ProgrammeBold",
  bottom = 0,
}) => {
  return (
    <Text
      allowFontScaling={false}
      style={[
        Styles.text,
        {
          fontSize: size,
          color: color,
          fontFamily: family,
          marginBottom: bottom,
        },
      ]}
    >
      {text}
    </Text>
  );
};

const Component = ({ message }) => (
  <View style={Styles.container}>
    <View style={Styles.position}>
      <Pattern />
    </View>

    <View style={Styles.position}>
      <Adjustable
        bottom={20}
        family="GTSuperDisplayRegular"
        text="Her şeye rağmen her zaman yanımda olan, bana destek veren ve daha ileriye adım atabilmek için bana yol gösteren, güç veren biricik sevgilim Buket'e."
        size={20}
      />
      <Adjustable
        bottom={20}
        family="GTSuperDisplayRegular"
        text="Bu muazzam uygulamayı hayata geçirebilmem için bana fırsat veren Zeyneb Uras, Emir Uras ve bütün Uras ailesine sonsuz teşekkür ederim. Sizler harika insanlarsınız."
        size={20}
      />
      <View style={Styles.title}>
        <View style={Styles.bar} />
        <Adjustable
          family="ChapLight"
          text="Julian Gumenita"
          size={24}
          color="#B47E55"
        />
      </View>
    </View>
  </View>
);

export default Component;

const Styles = {
  container: {
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    shadowColor: "#573623",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 4,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "absolute",
    top: 15 + Constants.statusBarHeight,
    bottom: 15,
    left: 15,
    right: 15,
    zIndex: 2,
    display: "flex",
  },
  position: {
    position: "absolute",
    top: 35,
    left: 35,
    right: 35,
    bottom: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    textAlign: "center",
    MozUserSelect: "none",
    KhtmlUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    fontWeight: "400",
    fontFamily: "ProgrammeBold",
  },
  bar: {
    width: 45,
    height: 2,
    backgroundColor: "#E0CDAA",
    marginBottom: 10,
  },
  title: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
};
