import React, { useState } from "react";
import { View, Text, TouchableOpacity, ImageBackground } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import Swipe from "./Swipe";
import Click from "./Click";
import Share from "./Share";

const Adjustable = ({
  size,
  text,
  color = "#000000",
  family = "ProgrammeBold",
  bottom = 0,
  align,
  left = 0,
  right = 0,
}) => {
  return (
    <Text
      allowFontScaling={false}
      style={[
        Styles.text,
        {
          fontSize: size,
          color: color,
          fontFamily: family,
          marginBottom: bottom,
          marginLeft: left,
          marginRight: right,
          textAlign: align,
        },
      ]}
    >
      {text}
    </Text>
  );
};

const Component = ({ close }) => {
  const [step, s] = useState(0);
  const [steps, ss] = useState([
    {
      image: require("../assets/public/onboarding-2.png"),
      gradients: ["rgba(125,114,80,0)", "rgba(125,114,80,1)"],
      color: "#F7F5F0",
      title: "Sensin!",
      description:
        "İster her sabah güne başlarken, ister dilediğin herhangi bir zamanda, kartlarını eline al, güzelce karıştır ve önüne yerleştir.",
    },
    {
      image: require("../assets/public/onboarding-1.png"),
      gradients: ["rgba(168,160,134,0)", "rgba(168,160,134,1)"],
      color: "#4A382A",
      title: "Sen Seç!",
      description:
        "Gözlerini kapat, derin bir nefes al, derin bir nefes ver. Sol elini kalbinin üstüne koyarak niyetinle buluş. Bu bir dilek, araştırma veya soru olabilir. Hazır hissettiğinde sağ elinle bir kart çek.",
    },
    {
      image: require("../assets/public/onboarding-3.png"),
      gradients: ["rgba(168,160,134,0)", "rgba(168,160,134,1)"],
      color: "#4A382A",
      title: "Haydi Başla!",
      description:
        "Aç gözlerini, tut kartı iki elinle, karşına çıkan çiçeğin ve kelimelerin yaydığı hisleri içine çek. Bazen su gibi akıp her şey yerini bulacak, işte diyeceksin. Harika! Bazen de seni sorgulattıracak, daha bile iyi! Güven evrenin enerjisine, dur bi bakalım. Hayat yanı başında. Olanla kalp kalbe kalabilir misin?",
    },
  ]);
  return (
    <View style={Styles.container}>
      <ImageBackground
        style={[
          Styles.absolute,
          {
            backgroundColor: steps[step].gradients
              ? steps[step].gradients[1]
              : undefined,
          },
        ]}
        source={steps[step].image || undefined}
      >
        <LinearGradient
          colors={steps[step].gradients || undefined}
          style={Styles.gradient}
          locations={[0.25, 0.75]}
        >
          <Adjustable
            family="SurtBold"
            color={steps[step].color}
            size={18}
            bottom={20}
            align="center"
            text={steps[step].title || undefined}
          />
          <Adjustable
            family="SurtLight"
            color={steps[step].color}
            size={16}
            bottom={20}
            align="center"
            text={steps[step].description || undefined}
          />
          <TouchableOpacity
            onPress={() => {
              if (step >= steps.length - 1) {
                if (close) {
                  close();
                }
              } else {
                s(step + 1);
              }
            }}
            style={Styles.button}
          >
            <Adjustable
              family="SurtBold"
              color="#494440"
              size={16}
              align="center"
              text={step >= steps.length - 1 ? "Tamamla!" : "Devam Et"}
            />
          </TouchableOpacity>
        </LinearGradient>
      </ImageBackground>
    </View>
  );
};

export default Component;

const Styles = {
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 4,
  },
  gradient: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    boxSizing: "border-box",
    padding: 35,
  },
  absolute: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  next: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    marginTop: 20,
  },
  button: {
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    shadowColor: "#573623",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 4,
    borderRadius: 4,
    padding: 12,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
};
