import React, { useState, useRef } from "react";
import { Dimensions, Text, View, ImageBackground, Share } from "react-native";
import * as Sharing from "expo-sharing";
import * as AssetUtils from "expo-asset-utils";
import { Asset } from "expo-asset";
import FlipCard from "react-native-flip-card";
import {
  TapGestureHandler,
  State,
  LongPressGestureHandler,
} from "react-native-gesture-handler";
import Icon from "./Icon";
import Pattern from "./Pattern";

const WIDTH =
  Dimensions.get("window").width - 64 > 305
    ? 305
    : Dimensions.get("window").width - 70;
const HEIGHT = WIDTH * 1.5;

const Adjustable = ({
  size,
  text,
  color = "#000000",
  family = "ProgrammeBold",
}) => (
  <Text
    allowFontScaling={false}
    style={[Styles.text, { fontSize: size, color: color, fontFamily: family }]}
  >
    {text}
  </Text>
);

const Component = ({ image, title, description, local }) => {
  const [flip, setFlip] = useState(false);
  const double = useRef();

  return (
    <View style={{ width: WIDTH, height: HEIGHT }}>
      <LongPressGestureHandler
        maxDist={5}
        onHandlerStateChange={async (event) => {
          try {
            if (flip) {
              Share.share(
                {
                  message: title + " - " + description + " https://sensin.app",
                  title,
                },
                { subject: title, dialogTitle: title }
              );
            } else {
              const loader = await Asset.loadAsync(image);
              await Sharing.shareAsync(loader[0].localUri);
            }
          } catch (Error) {
            console.error(Error);
          }
        }}
      >
        <TapGestureHandler
          maxDist={5}
          onHandlerStateChange={(event) => {
            try {
              if (event.nativeEvent.state === State.ACTIVE) {
                setFlip(!flip);
              }
            } catch (Error) {
              console.error(Error);
            }
          }}
        >
          <FlipCard
            flip={flip}
            clickable={false}
            friction={999}
            perspective={999}
            flipHorizontal={true}
            flipVertical={false}
            useNativeDriver={false}
          >
            <View style={{ width: WIDTH, height: HEIGHT, ...Styles.card }}>
              <View style={Styles.inside}>
                <ImageBackground style={Styles.background} source={image} />
              </View>
            </View>
            <View style={{ width: WIDTH, height: HEIGHT, ...Styles.card }}>
              <View style={Styles.inside}>
                <Icon />
                <View style={Styles.description}>
                  <Adjustable
                    family="GTSuperDisplayRegular"
                    text={description}
                    size={17}
                  />
                  <View style={Styles.position}>
                    <Pattern />
                  </View>
                </View>
                <View style={Styles.title}>
                  <View style={Styles.bar} />
                  <Adjustable
                    family="ChapLight"
                    text={title}
                    size={18}
                    color="#B47E55"
                  />
                </View>
              </View>
            </View>
          </FlipCard>
        </TapGestureHandler>
      </LongPressGestureHandler>
    </View>
  );
};

export default Component;

const Styles = {
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  position: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  description: {
    position: "absolute",
    top: 0,
    left: 20,
    right: 20,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bar: {
    width: 45,
    height: 2,
    backgroundColor: "#E0CDAA",
    marginBottom: 10,
  },
  title: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  card: {
    backgroundColor: "#FFFFFF",
    padding: 10,
    boxSizing: "border-box",
    shadowColor: "#573623",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 4,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  inside: {
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    flex: 1,
    padding: 35,
    boxSizing: "border-box",
    borderRadius: 2,
    border: "2px solid #C6A752",
    width: "100%",
    borderWidth: 2,
    borderColor: "#C6A752",
  },
  background: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
    borderRadius: 4,
    overflow: "hidden",
  },
  text: {
    textAlign: "center",
    MozUserSelect: "none",
    KhtmlUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    fontWeight: "400",
    fontFamily: "ProgrammeBold",
  },
};
