import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Component(props) {
  return (
    <Svg
      width={49}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M8.5 8c-2.206 0-4 1.794-4 4v6.344l4-4V12h32l.004 24H8.5v-2.344l-4-4V36c0 2.206 1.794 4 4 4h32c2.206 0 4-1.794 4-4V12c0-2.206-1.794-4-4-4h-32zm4 8l-8 8 8 8v-6h8.543a4 4 0 003.457 2 4 4 0 10-3.46-6H12.5v-6z"
        fill="#4A382A"
      />
    </Svg>
  )
}

export default Component
