import * as React from "react";
import { TouchableOpacity } from "react-native";
import Svg, { Path } from "react-native-svg";
import Constants from "expo-constants";

function Component(props) {
  const { color = "#E0CDAA", press } = props;
  return (
    <TouchableOpacity onPress={press} style={Styles.container}>
      <Svg
        width={14}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <Path fill={color} d="M0 0h2v20H0zM6 0h2v20H6zM12 0h2v20h-2z" />
      </Svg>
    </TouchableOpacity>
  );
}

export default Component;

const Styles = {
  container: {
    position: "absolute",
    zIndex: 3,
    top: 35 + Constants.statusBarHeight,
    right: 35,
  },
};
