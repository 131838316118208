import * as React from "react"
import Svg, { Path } from "react-native-svg"

function Component(props) {
  return (
    <Svg
      width={49}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M18.504 10c-1.678 0-2.61 1.94-1.563 3.25.38.474.955.75 1.563.75H36.5a2 2 0 012 2v12h-3.133c-1.156 0-1.796 1.336-1.074 2.238l4.809 6.016c.716.896 2.08.896 2.796 0l4.809-6.016c.722-.902.082-2.238-1.074-2.238H42.5V16a6 6 0 00-6-6H18.504zM8.5 11.074c-.52 0-1.04.224-1.399.672l-4.808 6.016C1.57 18.664 2.21 20 3.367 20H6.5v12a6 6 0 006 6h17.996c1.678 0 2.61-1.94 1.563-3.25a2.002 2.002 0 00-1.563-.75H12.5a2 2 0 01-2-2V20h3.133c1.156 0 1.796-1.336 1.074-2.238l-4.809-6.016a1.78 1.78 0 00-1.398-.672z"
        fill="#4A382A"
      />
    </Svg>
  )
}

export default Component
