import React, { useState } from "react";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  ImageBackground,
  Platform,
  StatusBar,
  Text,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Font from "expo-font";
import { Asset } from "expo-asset";
import SwipeCards from "react-native-swipe-cards";
import Lodash from "lodash";
import { Cards, Shaker } from "./Modules";
import {
  Card,
  Hamburger,
  Menu,
  Tutorial,
  Onboarding,
  Pattern,
  Message,
} from "./Components";
import "./assets/style.css";

const Adjustable = ({
  size,
  text,
  color = "#000000",
  family = "ProgrammeBold",
  bottom = 0,
  align,
  left = 0,
  right = 0,
}) => {
  return (
    <Text
      adjustsFontSizeToFit
      style={[
        Styles.text,
        {
          fontSize: size,
          color: color,
          fontFamily: family,
          marginBottom: bottom,
          marginLeft: left,
          marginRight: right,
          textAlign: align,
        },
      ]}
    >
      {text}
    </Text>
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      cards: [],
      menu: false,
      tutorial: false,
      onboarding: false,
      count: 0,
      shake: 0,
      message: false,
      locals: {},
    };
  }

  async componentDidMount() {
    const { shake } = this.state;

    Shaker.add(this.shake);

    this.setState({ cards: Lodash.shuffle(Cards) });
    const onboarding = await AsyncStorage.getItem(":onboarding");
    if (!onboarding) {
      this.setState({ onboarding: true, tutorial: true, loading: false });
      AsyncStorage.setItem(":onboarding", ":onboarding");
    }
    await Font.loadAsync({
      ProgrammeBold: require("./assets/fonts/ProgrammeBold.ttf"),
      ProgrammeRegular: require("./assets/fonts/ProgrammeRegular.ttf"),
      GTSuperDisplayRegular: require("./assets/fonts/GTSuperDisplayRegular.ttf"),
      ChapLight: require("./assets/fonts/ChapLight.ttf"),
      SurtBold: require("./assets/fonts/SurtBold.ttf"),
      SurtLight: require("./assets/fonts/SurtLight.ttf"),
    });
    await Asset.loadAsync(require("./assets/public/background.png"));
    await Asset.loadAsync(require("./assets/public/onboarding-1.png"));
    await Asset.loadAsync(require("./assets/public/onboarding-2.png"));
    await Asset.loadAsync(require("./assets/public/onboarding-3.png"));

    const images = [
      require("./assets/cards/Açıklık.jpg"),
      require("./assets/cards/Aidiyet.jpg"),
      require("./assets/cards/Alırlık.jpg"),
      require("./assets/cards/Amaç.jpg"),
      require("./assets/cards/An.jpg"),
      require("./assets/cards/Anbean.jpg"),
      require("./assets/cards/Aşk.jpg"),
      require("./assets/cards/Aydınlanma.jpg"),
      require("./assets/cards/Bağımlılık.jpg"),
      require("./assets/cards/Başlangıç.jpg"),
      require("./assets/cards/Benim.jpg"),
      require("./assets/cards/Birlik.jpg"),
      require("./assets/cards/Bütün.jpg"),
      require("./assets/cards/Çaba.jpg"),
      require("./assets/cards/Canlı.jpg"),
      require("./assets/cards/Cesaret.jpg"),
      require("./assets/cards/Çiçek.jpg"),
      require("./assets/cards/Dayanıklılık.jpg"),
      require("./assets/cards/Değişim.jpg"),
      require("./assets/cards/Denge.jpg"),
      require("./assets/cards/Destek.jpg"),
      require("./assets/cards/Dua.jpg"),
      require("./assets/cards/DurBakalım.jpg"),
      require("./assets/cards/Esneklik.jpg"),
      require("./assets/cards/Farkındalık.jpg"),
      require("./assets/cards/Hafiflik.jpg"),
      require("./assets/cards/Hayır.jpg"),
      require("./assets/cards/Hizmet.jpg"),
      require("./assets/cards/Huzur.jpg"),
      require("./assets/cards/İyilik.jpg"),
      require("./assets/cards/Kabul.jpg"),
      require("./assets/cards/Karanlık.jpg"),
      require("./assets/cards/Kılıf.jpg"),
      require("./assets/cards/Meditasyon.jpg"),
      require("./assets/cards/Merak.jpg"),
      require("./assets/cards/Mucize.jpg"),
      require("./assets/cards/Neşe.jpg"),
      require("./assets/cards/Niyet.jpg"),
      require("./assets/cards/OhhBe.jpg"),
      require("./assets/cards/Otantik.jpg"),
      require("./assets/cards/Özgür.jpg"),
      require("./assets/cards/Parlak.jpg"),
      require("./assets/cards/Pratik.jpg"),
      require("./assets/cards/Saf.jpg"),
      require("./assets/cards/Samimiyet.jpg"),
      require("./assets/cards/Şefkat.jpg"),
      require("./assets/cards/Sevgi.jpg"),
      require("./assets/cards/Sezgi.jpg"),
      require("./assets/cards/Şifa.jpg"),
      require("./assets/cards/Şimdi.jpg"),
      require("./assets/cards/Sorumluluk.jpg"),
      require("./assets/cards/Sükünet.jpg"),
      require("./assets/cards/Tanık.jpg"),
      require("./assets/cards/Yaratıcılık.jpg"),
      require("./assets/cards/Yavaşla.jpg"),
      require("./assets/cards/Yeni.jpg"),
      require("./assets/cards/Yoga.jpg"),
      require("./assets/cards/Yumuşaklık.jpg"),
    ];
    Promise.all(
      images.map(async (i, ii) => {
        try {
          const loader = await Asset.loadAsync(i);
        } catch (Error) {
          console.warn(Error);
        } finally {
        }
        this.setState({ count: this.state.count + 1 });
      })
    );

    this.setState({ loading: false });
  }

  componentDidUnmount() {
    Shaker.remove();
  }

  tutorial = () => {
    this.setState({ tutorial: true, menu: false });
  };

  close = () => {
    this.setState({ tutorial: false });
  };

  onboarding = () => {
    this.setState({ onboarding: false });
  };

  message = () => {
    this.setState({ message: false });
  };

  shake = () => {
    const { shake, menu } = this.state;
    this.setState({ shake: shake + 1 });

    if (shake >= 1 && menu) {
      this.setState({ message: true, shake: 0 });
    }
  };

  render() {
    console.disableYellowBox = true;

    const {
      loading,
      cards,
      menu,
      tutorial,
      onboarding,
      count,
      shake,
      message,
      locals,
    } = this.state;

    return loading ? (
      <ImageBackground
        source={require("./assets/public/background.png")}
        style={Styles.container}
      >
        <StatusBar barStyle="dark-content" />
        <Pattern />
        {count < cards.length ? (
          <View style={Styles.bottom}>
            <View style={Styles.button}>
              <Adjustable
                family="SurtBold"
                color="#494440"
                size={14}
                align="center"
                text={"Kartlar Yükleniyor " + count + "/" + cards.length}
              />
            </View>
          </View>
        ) : undefined}
      </ImageBackground>
    ) : (
      <ImageBackground
        source={require("./assets/public/background.png")}
        style={Styles.container}
      >
        <StatusBar barStyle="dark-content" />
        {onboarding ? <Onboarding close={this.onboarding} /> : undefined}
        {tutorial ? <Tutorial close={this.close} /> : undefined}
        {menu ? <Menu tutorial={this.tutorial} /> : undefined}
        {message ? <Message close={this.message} /> : undefined}
        <Hamburger
          color={menu || message ? "#C8BFBF" : "#B47E55"}
          press={() => this.setState({ menu: !menu, message: false, shake: 0 })}
        />
        <SwipeCards
          smoothTransition={true}
          cards={cards}
          renderCard={(card, cc) => (
            <Card
              key={card.title}
              title={card.title}
              description={card.description}
              image={card.image}
              local={() => card.image}
            />
          )}
          loop={true}
          smoothTransition={true}
          renderYup={() => <View />}
          renderNope={() => <View />}
          renderMaybe={() => <View />}
          onClickHandler={() => {}}
        />
        {count < cards.length ? (
          <View style={Styles.bottom}>
            <View style={Styles.button}>
              <Adjustable
                family="SurtBold"
                color="#494440"
                size={14}
                align="center"
                text={"Kartlar Yükleniyor " + count + "/" + cards.length}
              />
            </View>
          </View>
        ) : undefined}
      </ImageBackground>
    );
  }
}

const Styles = {
  container: {
    flex: 1,
    backgroundColor: "#F1E9E1",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  button: {
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    shadowColor: "#573623",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 4,
    borderRadius: 999,
    padding: 8,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  bottom: {
    position: "absolute",
    bottom: 35,
    left: 0,
    right: 0,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default App;
