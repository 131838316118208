import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Component(props) {
  return (
    <Svg
      width={24}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M9 18.14l6-6-6-6"
        stroke="#C8BFBF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default Component;
