import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Component(props) {
  return (
    <Svg
      width={209}
      height={187}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        opacity={0.2}
        d="M64.48 174.32c.2-69.06 73.81-68.84 74-137.9M182.77 107.05c0-43.31-35.11-78.42-78.42-78.42s-78.42 35.11-78.42 78.42 35.11 78.42 78.42 78.42 78.42-35.11 78.42-78.42z"
        stroke="#BD9D37"
        strokeOpacity={0.4}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <Path
        opacity={0.2}
        d="M3.46 158.63l100.9-155.6 100.89 155.6H3.46z"
        stroke="#BD9D37"
        strokeOpacity={0.4}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </Svg>
  );
}

export default Component;
