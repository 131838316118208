import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Component(props) {
  return (
    <Svg
      width={49}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M21.5 0c-6.051 0-11 4.949-11 11 0 1.228.229 2.38.586 3.43a2.001 2.001 0 103.789-1.29A6.588 6.588 0 0114.5 11c0-3.889 3.111-7 7-7s7 3.111 7 7c0 .732-.136 1.438-.375 2.14a2.003 2.003 0 002.024 2.643 2.001 2.001 0 001.765-1.353A10.63 10.63 0 0032.5 11c0-6.051-4.949-11-11-11zm0 8c-1.656 0-3 1.344-3 3v19.313l-5.688-2.883a3.244 3.244 0 00-3.468.34 3.202 3.202 0 00-.29 4.785l9.688 9.687A5.996 5.996 0 0022.984 44H34.5a6 6 0 006-6v-9.68a4.003 4.003 0 00-3.262-3.933L24.5 22V11c0-1.656-1.344-3-3-3z"
        fill="#4A382A"
      />
    </Svg>
  );
}

export default Component;
