const Cards = [
  {
    title: "OTANTİK",
    description:
      "Bir tane daha yok senin gibi tüm evrende. O kadar değerlisin. Kendine özgü rengini, çizgini, dokunuşunu paylaşmak için buradasın. Sadece sana özel bir yer var, lazımsın.",
    image: require("../assets/cards/Otantik.jpg"),
  },
  {
    title: "İYİLİK",
    description:
      "En temelinde ne var? Sonucu hep kendine mi dayanıyor? Seviyor mu, takdir ediyor mu, onaylıyor mu? Gizlice dokun bugün. Yap bir güzellik, bağırmadan, böbürlenmeden, karşılık beklemeden... Gözlemle kendini. Bakalım neler fark edeceksin.",
    image: require("../assets/cards/İyilik.jpg"),
  },
  {
    title: "SAMİMİYET",
    description:
      "Özünden kurduğun ilişki. Üzülmesin, kızmasın, alınmasın diye gerçeğinden vazgeçtiğin değil. Yaşam nehri içinden akıyor; korkusuz, dürüst, güvenli.",
    image: require("../assets/cards/Samimiyet.jpg"),
  },
  {
    title: "AÇIKLIK",
    description:
      "Sadece kendi doğruna saygından değil, karşındakiyle gerçekten ilişki kurabilmen için sevginin temeli. Başka yolu yok.",
    image: require("../assets/cards/Açıklık.jpg"),
  },
  {
    title: "PRATİK",
    description:
      "Elbette zihnin senin bir parçan ancak çok kısıtlı. Döngülerini, tekrarlarını, sınırlarını keşfet. Kalbine alan aç. Dönüşümün için her gün, kalpten, tekrar tekrar tekrar, olanı izle. Yaptığınla o kadar var ol ki, ne niyetle yola çıktığını bile unut gitsin. Teslim et çıkarları, hedefleri, beklentileri. Yolun, yaşamın pratiğin olsun.",
    image: require("../assets/cards/Pratik.jpg"),
  },
  {
    title: "YARATICILIK",
    description:
      "Yaratıcı olmadığına inanıyorsun. Heykeltıraş, ressam, müzisyen değilim, şiir yazmıyorum... Yaratıcı değilim diye düşünüyorsun. Ne büyük bir yanılsama. Her anın bir sanat eseri. İzin ver atsın kalbin güm güm, sarsın tatlı bir heyecan her yerini. Sev kendini, yaptığını. Gerçeğinde yaşadıkça sürekli yaratansın. Bu potansiyelle doğdun.",
    image: require("../assets/cards/Yaratıcılık.jpg"),
  },
  {
    title: "BİRLİK",
    description:
      "İç çekişin, kanın, kalp atışın, tenin, gülüşün hep aynı kaynaktan. Nefes alışın da, verişin de. Hatırla biriz, bütünüz. Senin özün, benim kalbim, onun canı hep bir. Üstümüze taktığımız kimlikleri birazcık kenara bıraktığımızda, çok çok açık.",
    image: require("../assets/cards/Birlik.jpg"),
  },
  {
    title: "CANLI",
    description:
      "Sensin. Ne büyük minnet! Çık geçmişten, gelecekten, gel bu ana, hisset ellerindeki enerjiyi. Nasıl kökleniyorsun ayaklarından toprağa. Tekrarla “Aldığım nefesteyim. Verdiğim nefesteyim. CANlıyım.”",
    image: require("../assets/cards/Canlı.jpg"),
  },
  {
    title: "SEVGİ",
    description:
      "Engin. Sonsuz. Katman katman açılıyor sana. Bu budur, hallettim dediğin yerde göz kırpacak sana. Haydi aydınlansın bir karanlık. İzin ver genişlesin nefesin özüne doğru az daha. Karşılaştığın her sıkıntı heyecanlandırsın seni. Özgürleşme zamanı.",
    image: require("../assets/cards/Sevgi.jpg"),
  },
  {
    title: "SAF",
    description:
      "Gerçekten bilebilir miyiz bir dakika, saat, gün sonrasını? Bütün bilgilerin, tecrübelerin muhteşem ancak bu an yepyeni. Fark et zihnin hikâyeler anlatmaya başladığında. Kalp hep geniş, hep saf.",
    image: require("../assets/cards/Saf.jpg"),
  },
  {
    title: "ŞİFA",
    description:
      "Kirli değilsin ki temizlenesin, şifalanasın. Her rengi barındıransın, rengârenksin. İnanma zihnin dediklerine, gül geç. Düşünceler, hisler gelip gidiyor, doğaları bu. Takılma çok. Bugün mavi, yarın kahverengi, bir diğer gün sarısın. Ne durumda hangi rengi parlatacaksın, sen karar ver",
    image: require("../assets/cards/Şifa.jpg"),
  },
  {
    title: "AŞK",
    description:
      "Sen bir aşk hikâyesisin! Yok artık desen dahi. Hem de nasıl! Senden sana, her şeye rağmen, her şey kanalıyla. Yaşamla el ele. Ne için atıyor kalbin? Neyle çoğalıyor emeğin? İşte tam bu bağ, yolun.",
    image: require("../assets/cards/Aşk.jpg"),
  },
  {
    title: "SEZGİ",
    description:
      "En güçlü güven alanın, kalbinin sesi. Uzak kalabiliyoruz zihnimizi dinledikçe. Hadi bir sakinle, dinle, duy, sok devreye sezgini. Kim ne derse desin. Bak nasıl yansıyacak içinden dışarıya, etrafına, ilişkilerine, nefesine.",
    image: require("../assets/cards/Sezgi.jpg"),
  },
  {
    title: "ALIRLIK",
    description:
      " Bir dur hayır demeden, reddetmeden. Dinle açıklıkla. Bir imkân sun kendine. Ne seni köstekliyor, ayırıyor? Ne yoluna destek, yardımcı gerçekten?",
    image: require("../assets/cards/Alırlık.jpg"),
  },
  {
    title: "NİYET",
    description:
      "Bağlan kalbinden, gerisini teslim et. Ne şekilde, ne zaman, gerekli mi? Bunların hepsini yaşam gösterecek.",
    image: require("../assets/cards/Niyet.jpg"),
  },
  {
    title: "ANBEAN",
    description:
      "Her olanla var olmak, bir nefesten diğerine. Bu farkındalık zorluğu aşmana fırsat tanıyor. Kaynakla bir, zihnin resimlerinden, hikâyelerinden uzak. Uyanık, sakin. Ne gerekiyorsa ona alan açabildiğin güvenli akış.",
    image: require("../assets/cards/Anbean.jpg"),
  },
  {
    title: "SORUMLULUK",
    description:
      "Hayatının sorumluluğu sadece sana ait. Ne zaman ki sorumluluğu bir başkasına devrediyorsun, o değişirse ben değişirim diyorsun işte o zaman terk ediyorsun tüm gücünü. Ne olacak ya başaramazsa? Kim toparlayacak, yönlendirecek, sevecek hayatını?",
    image: require("../assets/cards/Sorumluluk.jpg"),
  },
  {
    title: "ÖZGÜR",
    description:
      "Ayırma beni senden, seni benden. Dünyevi tercihlerimiz uyuşmayabilir, farklı görünebiliriz ancak kalplerimiz bir. Nasıl bir debelenmedir kendini başka bilip başka tutmak adına sürekli uğraşman, egonu iyice şişirmen. Nasıl bir esaret! Uyan bu yanılgıya, gerçek özgürlüğün tadını çıkar. Birbirimize dokunuyoruz, öyle ya da böyle. Kalbin zaten kendi şarkısını söylüyor.",
    image: require("../assets/cards/Özgür.jpg"),
  },
  {
    title: "ÇABA",
    description:
      "Her gün istemek, tekrar tekrar ziyaret etmek, adım atmak bir ileri iki geri, bir durmak, heyecanla koşmak, yenilemek, tutmak sımsıkı, bir o kadar da hafif.",
    image: require("../assets/cards/Çaba.jpg"),
  },
  {
    title: "MUCİZE",
    description:
      "Evet büyük bir kazadan kurtulmak bir mucize. Aynı zamanda her nefesin, her anın bir mucize. Öyle değil mi? Sen bir mucizesin. Şükür. O değeri ver kendine, o özenle yaşa.",
    image: require("../assets/cards/Mucize.jpg"),
  },
  {
    title: "HİZMET",
    description:
      "Her birimizin amacı hizmet etmek. Kendine, etrafına, evrene. Böyle diyor yoganın özü. Öyle terapist, doktor, öğretmen olmana da gerek yok. Ne ile ilgileniyorsan gözlemle. Ne kadar oradasın, kalbindesin? Her nefesin, bakışın, dokunuşun bu amacı barındırabilir mi? Bil ki kendine gerçekten hizmet ettiğinde tüm evrene hizmet ediyorsun.",
    image: require("../assets/cards/Hizmet.jpg"),
  },
  {
    title: "YENİ",
    description:
      "Hücrelerinin sürekli tazelendiği, yenilendiği gibi, hayatındaki her noktaya da tekrar ve yeniden bakabilirsin. O alan her daim senin. Haydi at o adımı, atla, zıpla, ara, git, sımsıkı sarıl. Önemli olan beklediğin karşılık değil, sezginden hareket edebilmenin hürlüğü.",
    image: require("../assets/cards/Yeni.jpg"),
  },
  {
    title: "AN",
    description:
      "Yaşadığım her ana şükürler olsun! An ve an ve an. Özgün, yepyeni, nasıl bereketli! Sana kalmış her anını hissetmek, değerlendirmek, sindirmek, kutlamak. Sana kalmış keyifle yaşamak.",
    image: require("../assets/cards/An.jpg"),
  },
  {
    title: "ŞİMDİ",
    description:
      "Yakala kendini sıkılıyorum derken. Nasıl sıkılabiliyorsun ki? Farklı bir anı aramak, geçmişe dalmak, değiştirmeye çalışmak seni sadece yaşamdan koparıyor. Şimdi hayatının hediyesi, farkındalığının yuvası, yarınının belirleyicisi.",
    image: require("../assets/cards/Şimdi.jpg"),
  },
  {
    title: "HUZUR",
    description:
      "Hayatla pazarlık yok. Şimdi olmaz yarın, bu değil diğeri, sıcak değil soğuk... Olanla, elindekiyle yüzleşebilir misin? Huzur senin potansiyelin, sorumluluğun, pratiğin.",
    image: require("../assets/cards/Huzur.jpg"),
  },
  {
    title: "HAYIR",
    description:
      "Gönlünden geçmiyorsa hayır de. Kendine yapabileceğin en büyük fayda bu olabilir. Aynı değerde, gerçeğini paylaştığın için karşındakine de destek olacaksın, yolunu açacaksın.",
    image: require("../assets/cards/Hayır.jpg"),
  },
  {
    title: "DUR BAKALIM",
    description:
      "Buradan itiyorsun öbür taraftan beliriyor, bitti bu iş diyorsun tekrar ortaya çıkıyor. Seni sıkıyor, canını yakıyor. Doğrularının, bildiklerinin ötesinde bir şeyler oluyor. Dur bakalım! Su serp o güzel kalbine. Hayat bir şeyler göstermeye çalışıyor senin hayrına. Gelişme, dönüşme, sevme zamanı.",
    image: require("../assets/cards/DurBakalım.jpg"),
  },
  {
    title: "YOGA",
    description:
      "Yaşam Yogam! Her anın bir diğeri kadar değerli ve sana özel biçilmiş kaftan. Aynı zamanda burası senin pratik alanın. Her şey olması gerektiği gibi.",
    image: require("../assets/cards/Yoga.jpg"),
  },
  {
    title: "TANIK",
    description:
      "Çok sevdiğin bir dostunun paylaşımını dinlersin ya. Hem şefkatlisindir hem de uyanık kalırsın, gözlemlersin geniş alanı, bütünü değerlendirmeye çalışırsın. İşte bu hali kendine biraz mesafeden bakıp her anına sunabilir misin? Hiç yapamıyorum diyeceksin ilkten. Merak etme her uyanık an bir diğerini getiriyor, besliyor. Ve bu anlar birikiyor.",
    image: require("../assets/cards/Tanık.jpg"),
  },
  {
    title: "DESTEK",
    description:
      "Olan her şey, ilişkilerin, hissettiklerin, düşüncelerin, sevdiğin, tercih etmediğin, hepsi uyanışının bir parçası. Hiçbir şey köstek değil, destek. Ve aynı değerde kutsal.",
    image: require("../assets/cards/Destek.jpg"),
  },
  {
    title: "KABUL",
    description:
      "Ruhunun dokusu. Yolculuğunda ne olmaktaysa bunlar seni desteklemek için. Zorlayanlar görüleceklerin işaretçisi. Aniden çıkan reddin farkına var. Belki yaşam senin için daha iyisini biliyor. Derin bir nefes al ve bırak gitsin. Er ya da geç fark edeceksin tılsımı.",
    image: require("../assets/cards/Kabul.jpg"),
  },
  {
    title: "OHH BE!",
    description:
      "Bugün derin bir ohh be çek ve tekrarla: şükür kavuşturana, şükür sana, bize, olduğumuz yere! Şükür her anıma, her halime. Çıkmışsın karşıma, vardır bir hediyen! Teşekkür ediyorum. Teşekkür ediyorum. Teşekkür ediyorum.",
    image: require("../assets/cards/OhhBe.jpg"),
  },
  {
    title: "ŞEFKAT",
    description:
      "Bak dikkatle, gözlemle kendinde görmek istemediklerini. Sürekli sunuyor yaşam sana yargıladıklarını, sakladıklarını. Hiçbir ilgim, benzerliğim olamaz dediğin o adamla veya kadınla yan yana gelmemek için uğraşır durursun. Ancak bir şekilde çıkar tekrar karşına, kendisi olmasa da uyandırdığı his. Ayna tutuyor hayat sana. Ne kadar dürüstsen o kadar anlayış, sevgi, şefkat.",
    image: require("../assets/cards/Şefkat.jpg"),
  },
  {
    title: "YAVAŞLA",
    description:
      "Bir yavaşla arkadaşım! Tüm gün oradan oraya koşturuyorsun. Öyle alışmışsın ki sen dursan zihnin durmuyor. ‘Neden durdun? Az yaptın! Boşa geçiyor tüm zamanın!’ Yap yap yap! Sanki yapmakla var olmanın dengesi kaçmış. Ne kadar varsın yaptıklarının temelinde, sor kendine. Belki yaptıklarından çok nasıl yaptığındır esas bereketi sağlayan.",
    image: require("../assets/cards/Yavaşla.jpg"),
  },
  {
    title: "KILIF",
    description:
      "Karar verdin bu iş böyle gitmiyor. Çalışmaya başladın mutlu olmadığın kılıfından kurtulmak için. Ancak dikkat et, yargıladığın unsurlardan kurtulduğunu düşündükçe saplanabilirsin çok daha sinsi, zorlu bir kılıfa. Spiritüel kılıf da diyebilirsin buna. Özünün kılıfa ihtiyacı yok. Değiştirilecek bir tarafı da. Bol gözlem, bol kabul, bol anlayış ihtiyacın olan. Hayali sen mi, otantik sen mi?",
    image: require("../assets/cards/Kılıf.jpg"),
  },
  {
    title: "DUA",
    description:
      "Yaptıkların, alışın, kabulün, dokunuşun, varoluşun, olmakta olana kalbinden teşekkürün.",
    image: require("../assets/cards/Dua.jpg"),
  },
  {
    title: "KARANLIK",
    description:
      "Ne kadar karanlık o kadar aydınlık, o kadar bereket. Seni uyandırmak, hafifletmek için burada. Karşı koyma, sakinle, yaslan arkana. Yalnız değilsin. Keşfetmeye değer. Bağın güçlenecek. Bir nevi tekrardan doğmaktasın.",
    image: require("../assets/cards/Karanlık.jpg"),
  },
  {
    title: "BEN",
    description:
      "Doğarken seninleydi, belki daha da öncesinden. Hiçbir zaman değişmeyen. Zamanın ötesinde, yaşının, boyunun, renginin, becerinin... ötesinde. Özün, en kıymetli, sadık dostun. Yaşanası!",
    image: require("../assets/cards/Benim.jpg"),
  },
  {
    title: "MERAK",
    description:
      "Koy doğrularını, yanlışlarını bir kenara. Bilinmeyen hafif hafif ürkütsün seni, sorun değil. Yaklaş çok tanıdığın olaya, kişiye yepyeni bir bakışla. Merak heyecanlandırsın seni olasılıklara. Hatırla, bu an yeni, canlı, üretken, cömert.",
    image: require("../assets/cards/Merak.jpg"),
  },
  {
    title: "BAĞIMLILIK",
    description:
      "İllaki bağımlıyız, bir maddeye, şekle, harekete veya düşünceye. Memnun olmadığımız her andan kaçıyoruz. Yalnızlık hissinden, yüzleşmekten, güçsüzlük, değersizlik yalanlarından. Şöyle elinle omzuna dokun, kucakla, sar kendini. Yanlış yok, her şey olması gerektiği gibi. Sen iste yeter.",
    image: require("../assets/cards/Bağımlılık.jpg"),
  },
  {
    title: "DAYANIKLILIK",
    description:
      "Hassasiyetin en şahane tılsımın. Savunmasız hissettiğin her anla yüzleşmen, kaçmaman, paylaşman, bak nasıl kavrayacak seni. Seni sana sunacak. Hayatla bağın çok güçlü. Sağlamsın. Altından kalkamayacağın hiçbir şey çıkmıyor karşına.",
    image: require("../assets/cards/Dayanıklılık.jpg"),
  },
  {
    title: "ESNEKLİK",
    description:
      " Ne olduysa oldu. Evet canın yandı, yanıyor. Toparla kendini. Tazele güvenini, vardır hepsinin bir yeri. Gir kalbine. Nasıl sindirdiğin dönüşümünü belirliyor, geleceğini, belki ötesini.",
    image: require("../assets/cards/Esneklik.jpg"),
  },
  {
    title: "AMAÇ",
    description:
      "Dokunmak, ufacık da olsa bir şekilde dokunabilmek. Kalpten gülümsemek. İçindeki, bir diğerindeki, her birimizdeki ışığa saygı duymak. Kutsallığımızı fark edebilmek. Hiçbir fikrinin olmadığı, kendini amaçsız gördüğün zamanlara da güvenerek.",
    image: require("../assets/cards/Amaç.jpg"),
  },
  {
    title: "SÜKÛNET",
    description:
      "Yaşamla, doğayla birliğinin ağı. Çaktırmadan işler, farkına varılmayı bekler. Her çığlığı, hareketi, adımı kaplar. Yaratıcılığın kaynağı. Düşüncelerin azalmasıyla alan sunmaya başlayabilirsin. Gel buraya bu ana. Dinleyebilirsin de çok heyecanlı.",
    image: require("../assets/cards/Sükünet.jpg"),
  },
  {
    title: "FARKINDALIK",
    description:
      "Burnunun ucunda. Hayat, üstündeki yükleri temizleyebilmen, gelişebilmen, mutlu olabilmen için gerekeni sana zaten veriyor. Düşmesiyle, kalkmasıyla, zorlanmasıyla, neşesiyle. Sonsuz, yüce bir zekâ seninle. Bırak o ne yapıyor, bu ne diyor, gel sen nefesine. Ne değerli her nefesin!",
    image: require("../assets/cards/Farkındalık.jpg"),
  },
  {
    title: "AYDINLANMA",
    description:
      "Senden uzak değil. Sakın büyütüp arkanı dönme. Her birimizin değişik seviyelerde yaşam alanı. Toprağa attığın tohum gibi, özü sende ve gelişiyor. Kendini hatırladığın her an şahane. Tutkun, pratiğin ve hayatın desteğiyle hare hare yayılarak yükseltiyor, hafifletiyor, derinleştiriyor... Sevgide yüceltiyor.",
    image: require("../assets/cards/Aydınlanma.jpg"),
  },
  {
    title: "PARLAK",
    description:
      "Parlat kendini parlatabildiğin kadar. Her gün kalbinde kalmak, araştırman, pratiğin olsun. Tüm evrene yayıyorsun ışığını. Beraber aydınlanıyoruz.",
    image: require("../assets/cards/Parlak.jpg"),
  },
  {
    title: "CESARET",
    description:
      "Cesaret istiyor özgün bağ kurmak, bilinenin dışına çıkmak. En ferahlatıcısı bunun zaten senin kalbinde olması. Hayat yanında merak etme, çalışıyor seninle. Zihninin anlattığından o kadar daha geniş, kapsayan, sevensin ki.",
    image: require("../assets/cards/Cesaret.jpg"),
  },
  {
    title: "HAFİFLİK",
    description:
      "Bir bak biriktirdiklerine. İnsanlar, eşyalar, duygular, düşünceler, doğrular, yanlışlar... Hafiflemek ne iyi olur. Seni desteklemeyenleri, yolunu tıkayanları üfle gitsin. Belki başkasının işine yarar.",
    image: require("../assets/cards/Hafiflik.jpg"),
  },
  {
    title: "BÜTÜN",
    description:
      "Bırak ağacın dallarını, yapraklarını, bak şöyle bir ormana. Hayatında yanlış yok. Hiçbir durum, kişi, şekil öylesine orada değil. Kal bununla biraz. Elbette bir yeri var gerçekliğinde.",
    image: require("../assets/cards/Bütün.jpg"),
  },
  {
    title: "DENGE",
    description:
      "Yaptıklarını dengeleyebilir misin varoluşunla? Ne kadar farkındasın hayatının içinde? Günler, sen hiç orada olmadan akıp gidiyor mu? Tamam şimdi görüyorsun. Her uyanık adım biraz daha dengeliyor bizi.",
    image: require("../assets/cards/Denge.jpg"),
  },
  {
    title: "MEDİTASYON",
    description:
      "Yuvaya dönüş, başka bir yere, ana kaçış değil. Hayatın sana dokunduğu anda tutar mısın elini? Şimdi, burada ve bu anda. Nasıl olurdu hayatla el ele ilerlemek, doğalından? Bırak kontrolü, beklentileri, idealleri. Hatırla canlılığını, merakla alan aç öfkene, korkuna, neşene, her haline. Zihinden kalbe.",
    image: require("../assets/cards/Meditasyon.jpg"),
  },
  {
    title: "DEĞİŞİM",
    description:
      "Doğanın nabzı. Olmazsa olmazın. Ne yükseliyorsa alçalacak, ne kapanıyorsa açılacak. Son derece basit, temel. Nefesinin girişi, çıkışı gibi. Sana yardımcı olacak değişim süresince gidene değil gelene odaklanman.",
    image: require("../assets/cards/Değişim.jpg"),
  },
  {
    title: "BAŞLANGIÇ",
    description:
      "Hiçbir anın bir daha tekrarlanmadığını biliyoruz ancak her yeni anın bize tekrar başlama fırsatı verdiğinin ne kadar farkındayız? Ne heyecanlı bir keşif değil mi? Hayatımız başlangıçlardan oluşuyor. Sen adımını at yeter, hayat her hareketini destekliyor.",
    image: require("../assets/cards/Başlangıç.jpg"),
  },
  {
    title: "NEŞE",
    description:
      "Anlık kahkahaların, pozitif kalıpların ötesinde, ne olursa olsun, derinden anlayış, kabul, hafiflik, teşekkür.",
    image: require("../assets/cards/Neşe.jpg"),
  },
  {
    title: "AİDİYET",
    description:
      "Tüm duygular görülmek istiyor. Zorlayanlar en başta. Senin parçan onlar. Yaramaz çocuğunu dışarıda bırakmadığın, diğerlerinden ayırmadığın gibi bırak gelsinler, görülsünler, çözülsünler, açsınlar kalbini. Hepsine yer var.",
    image: require("../assets/cards/Aidiyet.jpg"),
  },
  {
    title: "YUMUŞAKLIK",
    description:
      "İstemiyorsan çıkma dışarı bugün. Toparlama evi. Açma telefonu, halledersin sonra. Sun kendine bu yumuşak, sevecen alanı. Küçük küçük rahatlatmaya başla kendine koyduğun kuralları.",
    image: require("../assets/cards/Yumuşaklık.jpg"),
  },
  {
    title: "ÇİÇEK",
    description:
      "Eşsiz. Rengârenk. Mutluluk. Umut. Hediye veren. Açan, solan, süren. Can, sen, ben.",
    image: require("../assets/cards/Çiçek.jpg"),
  },
];

export default Cards;
