import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import Swipe from "./Swipe";
import Click from "./Click";
import Share from "./Share";

const Adjustable = ({
  size,
  text,
  color = "#000000",
  family = "ProgrammeBold",
  bottom = 0,
  align,
  left = 0,
  right = 0,
}) => {
  return (
    <Text
      allowFontScaling={false}
      style={[
        Styles.text,
        {
          fontSize: size,
          color: color,
          fontFamily: family,
          marginBottom: bottom,
          marginLeft: left,
          marginRight: right,
          textAlign: align,
        },
      ]}
    >
      {text}
    </Text>
  );
};

const Component = ({ close }) => {
  const [step, s] = useState(0);
  const [steps, ss] = useState([
    {
      icon: <Swipe />,
      title: "Kartları Gör",
      description:
        "Kartları sağa veya sola atarak diğer kartlara geçiş sağlayabilirsin.",
    },
    {
      icon: <Click />,
      title: "Kartları Oku",
      description:
        "Kartlara dokunarak arkasını çevirebilir ve kartları okuyabilirsin.",
    },
    {
      icon: <Share />,
      title: "Paylaş",
      description:
        "Kartları sevdiklerinle paylaşabilmek için uzun süre basılı tut.",
    },
  ]);

  return (
    <View style={Styles.container}>
      <LinearGradient
        colors={["rgba(206,190,178,0)", "rgba(206,190,178,1)"]}
        style={Styles.gradient}
        locations={[0.25, 0.75]}
      >
        {steps[step].icon || undefined}
        <View style={Styles.next}>
          <Adjustable
            family="SurtLight"
            color="#494440"
            size={16}
            right={5}
            left={5}
            align="center"
            text={step + 1 + "/" + steps.length}
          />
          <Adjustable
            family="SurtBold"
            color="#494440"
            size={16}
            right={5}
            left={5}
            align="center"
            text={steps[step].title || undefined}
          />
        </View>
        <Adjustable
          family="SurtLight"
          color="#494440"
          size={16}
          bottom={20}
          align="center"
          text={steps[step].description || undefined}
        />
        <TouchableOpacity
          onPress={() => {
            if (step >= steps.length - 1) {
              if (close) {
                close();
              }
            } else {
              s(step + 1);
            }
          }}
          style={Styles.button}
        >
          <Adjustable
            family="SurtBold"
            color="#494440"
            size={16}
            align="center"
            text={step >= steps.length - 1 ? "Tamamla!" : "Devam Et"}
          />
        </TouchableOpacity>
      </LinearGradient>
    </View>
  );
};

export default Component;

const Styles = {
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
  },
  gradient: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    boxSizing: "border-box",
    padding: 35,
  },
  next: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    marginTop: 20,
  },
  button: {
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    shadowColor: "#573623",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 4,
    borderRadius: 4,
    padding: 12,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
};
