import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  ImageBackground,
} from "react-native";
import Constants from "expo-constants";
import * as WebBrowser from "expo-web-browser";
import Icon from "./Icon";
import Right from "./Right";
import Shop from "./Shop";

const HEIGHT = Dimensions.get("window").height - 30 - Constants.statusBarHeight;
const WIDTH =
  Dimensions.get("window").width - 30 > 500
    ? HEIGHT / 1.5
    : Dimensions.get("window").width - 30;

const Adjustable = ({
  size,
  text,
  color = "#000000",
  family = "ProgrammeBold",
  bottom = 0,
}) => {
  return (
    <Text
      allowFontScaling={false}
      style={[
        Styles.text,
        {
          fontSize: size,
          color: color,
          fontFamily: family,
          marginBottom: bottom,
        },
      ]}
    >
      {text}
    </Text>
  );
};

const Menu = ({
  title,
  description,
  press,
  end = false,
  start = false,
  icon = false,
  image = false,
}) => (
  <TouchableOpacity
    onPress={press}
    activeOpacity={!press ? 1 : 0.2}
    style={[
      Styles.menu,
      end ? {} : { borderBottomWidth: 1, borderColor: "rgba(80, 81, 59, 0.1)" },
      start ? { borderTopWidth: 1, borderColor: "rgba(80, 81, 59, 0.1)" } : {},
    ]}
  >
    <View style={Styles.row}>
      {image ? (
        <ImageBackground source={{ uri: image }} style={Styles.image} />
      ) : undefined}
      <View style={Styles.item}>
        <Adjustable
          family="SurtBold"
          size={13}
          bottom={4}
          color="#50513B"
          text={title}
        />
        {description ? (
          <Adjustable
            family="SurtLight"
            size={14}
            color="#C8BFBF"
            text={description}
          />
        ) : undefined}
      </View>
    </View>
    {press ? icon ? icon : <Right /> : undefined}
  </TouchableOpacity>
);

const Component = ({ tutorial }) => (
  <View style={{ ...Styles.container, width: WIDTH, height: HEIGHT }}>
    <View style={Styles.position}>
      <Icon />
    </View>
    <Menu
      image="https://sensin.app/images/shop.jpg"
      press={() =>
        WebBrowser.openBrowserAsync(
          "https://www.zeyneburas.com/tr/shop/28/cards-zeyneb-uras/"
        )
      }
      title="Sensin Kartları"
      description="Fiziksel kartları satın al"
      start={true}
      icon={<Shop stroke="#B47E55" />}
    />
    <Menu
      press={tutorial}
      title="Nasıl Kullanılır"
      description="Öğreticiyi tekrardan çalıştırın"
    />
    <Menu
      press={() => WebBrowser.openBrowserAsync("https://sensin.app")}
      title="Hakkımızda"
      description="Sensin’in hikayesini öğrenin"
    />
    <Menu
      press={() =>
        WebBrowser.openBrowserAsync("https://sensin.app/privacy-policy.html")
      }
      title="Gizlilik Poliçesi ve KVKK"
    />
    <Menu
      press={() =>
        WebBrowser.openBrowserAsync("https://sensin.app/terms-of-use.html")
      }
      title="Kullanım Koşulları"
    />
    <Menu title="Versiyon" description="1.1" end={true} />
  </View>
);

export default Component;

const Styles = {
  menu: {
    display: "flex",
    alignItems: "center",
    padding: 20,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  container: {
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    shadowColor: "#573623",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    elevation: 4,
    borderRadius: 4,
    justifyContent: "flex-end",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
    display: "flex",
  },
  position: {
    position: "absolute",
    top: 20,
    left: 20,
  },
  text: {
    textAlign: "center",
    MozUserSelect: "none",
    KhtmlUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    fontWeight: "400",
    fontFamily: "ProgrammeBold",
  },
  item: {
    display: "flex",
    alignItems: "flex-start",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: 60,
    width: 60,
    minWidth: 60,
    borderRadius: 4,
    marginRight: 15,
    overflow: "hidden",
  },
};
